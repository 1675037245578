import * as React from "react";
import { AlertColor, Box, Stack, TextField } from "@mui/material";
import { useAddCclSystemMutation } from "../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../utilities/generalUtilities";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../components/common/cclButtons/cclLoadingButton";

export interface AddSystemDrawerProps {
    open: boolean;
    onClose: () => void;
    showSnackbar: (msg: string, severity: AlertColor) => void;
}

export const AddSystemDrawer: React.FC<AddSystemDrawerProps> = (props) => {
    const [newSystemName, setNewSystemName] = React.useState<string>("");
    const [addSystem, { isLoading }] = useAddCclSystemMutation();

    const onAddSystem = () => {
        addSystem({
            name: newSystemName,
            systemId: generateGUID(),
        })
            .unwrap()
            .then((res: boolean) => {
                props.showSnackbar("System Created", "success");
                props.onClose();
            })
            .catch((err) => {
                props.showSnackbar("System Creation Failed", "error");
                props.onClose();
            });
    };

    return (
        <CclDrawer open={props.open} onClose={() => props.onClose()} title="Create System">
            <Box>
                <Stack spacing={3}>
                    <TextField
                        required
                        id="new-system-name"
                        label="New System Name"
                        variant="standard"
                        value={newSystemName}
                        onChange={(e) => setNewSystemName(e.target.value)}
                    />
                </Stack>
            </Box>
            <CclDrawerActionBar>
                <CclLoadingButton
                    onClick={() => onAddSystem()}
                    disabled={newSystemName === ""}
                    mode={"primary"}
                    loading={isLoading}
                >
                    Save
                </CclLoadingButton>
                <CclButton onClick={props.onClose} mode={"secondary"}>
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddSystemDrawer;
