import * as React from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import FlexGrid from "../../../../layouts/flexGrid";
import { useMapChildParentkeyMutation } from "../../../../services/cclTokenedCustomerHierarchyApi";
import { HomeIndustriesList, MapChildParentRequest } from "../../../../services/types/clientTypes";
import HomeIndustriesSubDataGridPro from "./homeIndustriesSubDataGridPro";
import HomeIndustriesParentChild from "./homeIndustriesParentChild";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type HomeIndustriesPanelParams = {};

export const HomeIndustriesPanel: React.FC = (prop) => {
    // redux state
    const [parentChildTab, setParentChildTab] = React.useState<boolean>(true);
    const [parentSelectData, setParentSelectData] = React.useState<HomeIndustriesList[]>();
    const [childSelectData, setChildSelectData] = React.useState<HomeIndustriesList[]>();
    const [saveChildParentMap, { isSuccess }] = useMapChildParentkeyMutation();
    const [confirmSave, setConfirmSave] = React.useState<boolean>(false);

    const OnSelectDataList = (parentChildDataSelect: HomeIndustriesList[]) => {
        if (parentChildTab) setParentSelectData(parentChildDataSelect);
        else setChildSelectData(parentChildDataSelect);
    };

    React.useEffect(() => {
        if (isSuccess) {
            setParentSelectData([]);
            setChildSelectData([]);
        }
    }, [isSuccess]);

    const OnCancel = () => {
        setParentSelectData([]);
        setChildSelectData([]);
    };

    const OnMapChildParent = () => {
        if (
            parentSelectData &&
            parentSelectData?.length > 0 &&
            childSelectData &&
            childSelectData?.length > 0
        ) {
            const saveRequestPayload: MapChildParentRequest = {
                mappingChildKey: childSelectData[0].childKey.toString(),
                newChildKey: parentSelectData[0].childKey,
                newParentKey: parentSelectData[0].parentKey,
            };
            saveChildParentMap(saveRequestPayload).unwrap().then(() => {setConfirmSave(true)});
            setParentSelectData([]);
            setChildSelectData([]);
        }
    };

    const updateParentChild = (value: boolean) => {
        setParentChildTab(value);
    }

    return (
        <Stack height={1} width={1}>

            <CclAlertSnackbar 
                open={confirmSave} 
                message={isSuccess ? "Mapped User to Client" : "Failed to map user to client"}
                severity={isSuccess ? "success" : "error"}  
                onClose={() => setConfirmSave(false)}            
            />

           <CclGenericAccordian headingText={"Create Mapping(s)"} sx={{mb: 6}} defaultExpanded={true}>
                <Box
                    sx={{
                        width: 1,
                        m: 2,
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Alert sx={{mb: 3}} severity="info">Please Select a parent Client and one or more child Clients to create Client Mapping(s)</Alert>
                    
                    <Typography variant="caption" display="block" gutterBottom>
                        The following client(s)
                    </Typography>
                    
                    <FlexGrid>
                        <HomeIndustriesSubDataGridPro
                            homeIdustriesList={childSelectData}
                            isLoading={false}
                        />
                    </FlexGrid>

                    <Typography variant="caption" display="block" gutterBottom>
                        will now have the following parent...
                    </Typography>

                    <FlexGrid>
                        <HomeIndustriesSubDataGridPro
                            homeIdustriesList={parentSelectData}
                            isLoading={false}
                        />
                    </FlexGrid>

                    <Stack direction={"row"}>
                        <CclButton 
                            onClick={()=> OnCancel()}
                            sx={{
                                width: 0.1,
                                m: 2,
                                mt: 4,
                                ml: "auto",
                            }}
                            mode={"primary"}> 
                            Cancel
                        </CclButton>
                        <CclButton
                            onClick={(evt) => {
                                evt.stopPropagation();
                                OnMapChildParent();
                            } }
                            disabled={!(
                                parentSelectData &&
                                parentSelectData?.length > 0 &&
                                childSelectData &&
                                childSelectData?.length > 0
                            )}
                            sx={{
                                width: 0.1,
                                m: 2,
                                mt: 4,
                            }} mode={"primary"}>
                            Save Mapping(s)
                        </CclButton>
                    </Stack>
                </Box>  
            </CclGenericAccordian>

            <CclGenericAccordian headingText="Search Clients">
                <HomeIndustriesParentChild
                    OnSelectRowData={OnSelectDataList}
                    updateParentChild={updateParentChild}
                />
            </CclGenericAccordian>
        </Stack>
    );
};

export default HomeIndustriesPanel;
