import * as React from "react";
import {
    DataGridPro,
    GridSelectionModel,
    GridRowId,
} from "@mui/x-data-grid-pro";
import { AccountCEResponse } from "../../../../services/types/clientTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface CESystemUserDataGridProProps {
    accountCELists: AccountCEResponse[] | undefined;
    showToolbar: boolean;
    OnSelectDetails: (systemUserID: string) => void;
    isLoading: boolean;
}

export const CESystemUserDataGridPro: React.FC<CESystemUserDataGridProProps> = (
    props
) => {
    const COLUMNS = [
        {
            field: "ccL_Departmentname",
            headerName: "Department Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "businessUnitIdName",
            headerName: "Business Unit Name",
            type: "string",
            flex: 1,
        },
        {
            field: "firstName",
            headerName: "First Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "lastName",
            headerName: "Last Name",
            type: "string",
            flex: 1,
        },
        {
            field: "internalEmailAddress",
            headerName: "Email Address",
            type: "string",
            flex: 1,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids || !props.accountCELists) {
            return;
        }
        const selectedRowsData = ids.map((id) => props.accountCELists?.find((row) => row.systemUserId=== id));
        setSelectedRows(selectedRowsData);
    };

    const handleSelect = () => {
        props.OnSelectDetails(selectedRows[0].systemUserId);
    }

    return (
        <DataGridPro
            getRowId={(row) => row.systemUserId}
            rows={props.accountCELists == null ? [] : props.accountCELists}
            columns={COLUMNS}
            loading={props.isLoading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    savestate: null,
                    clearstate: null,
                    miscButtons: 
                        [
                            {
                                id: "select-industry",
                                caption: `Select`,
                                label: `Select`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    handleSelect();
                                }
                            }
                        ],
                        btnClick: (() => handleSelect()),
                        hideSettingsButtons: true,
                        anySelected: null,
                    }
            }}
            disableMultipleSelection
            disableSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: "systemUserId", sort: "asc" }],
                },
            }}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={(id: GridSelectionModel) => {
                if (id.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = id.filter((s) => !selectionSet.has(s));
                    handleSelection(result);
                    setSelectionModel(result);
                } else {
                    handleSelection(id);
                    setSelectionModel(id);
                }
            }}
        />       
    );
};

export default CESystemUserDataGridPro;
