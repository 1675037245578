import * as React from "react";
import { Alert, Box, MenuItem, Select, Stack, TextField } from "@mui/material";
import { UpdateWorkPool, WorkPoolType } from "../../../services/types/resources/workPoolTypes";
import {
    useAddNewCadreMutation,
    useGetAllCadreTypesQuery,
} from "../../../services/cclTokenedEnterpirseCoachingCadreApi";
import { AccessEventWorkPoolDetails } from "../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclDrawer from "../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../components/common/cclButtons/cclLoadingButton";
import CclDrawerActionBar from "../../../components/common/cclDrawer/cclDrawerActionBar";

export interface AddWorkPoolDrawerProps {
    open: boolean;
    status: (success: boolean) => void;
    onClose: (trigger: boolean) => void;
}

export const AddWorkPoolDrawer: React.FC<AddWorkPoolDrawerProps> = (props) => {
    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [ctKey, setCtKey] = React.useState<number>(1);

    const [addNewCadre, { isLoading, isError }] = useAddNewCadreMutation();
    const { data: allCadreTypes, isLoading: cadreTypesLoading } = useGetAllCadreTypesQuery();

    const { logEvent } = useLogAccessEvent();

    const addWorkPool = () => {
        const newWorkPool: UpdateWorkPool = {
            cKey: 0,
            name: name,
            description: description,
            ctKey: ctKey,
        };

        addNewCadre(newWorkPool)
            .unwrap()
            .then((e) => {
                props.status(isError);
                const evtData: AccessEventWorkPoolDetails = {
                    workPoolId: `${e.cKey}`,
                    workPoolName: e.name,
                };

                logEvent("WorkPoolCreated", evtData);
                props.onClose(true);
            });
    };

    return (
        <CclDrawer
            title={"Create Work Pool"}
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <Stack spacing={3}>
                <TextField
                    value={name}
                    onChange={(e) => {
                        e.preventDefault();
                        setName(e.target.value);
                    }}
                    sx={{ width: 1, maxHeight: "57px", marginTop: "10px" }}
                    variant="outlined"
                    label="Name"
                ></TextField>
                <TextField
                    value={description}
                    onChange={(e) => {
                        e.preventDefault();
                        setDescription(e.target.value);
                    }}
                    sx={{ width: 1, maxHeight: "57px" }}
                    variant="outlined"
                    label="Description"
                ></TextField>
                <Box width={"100%"}>
                    {allCadreTypes && (
                        <>
                            <Select
                                sx={{
                                    width: "100%",
                                }}
                                defaultValue={1}
                                label={"Select Work Pool Type"}
                                onChange={(e) => {
                                    setCtKey(
                                        typeof e.target.value === "number" ? e.target.value : 1
                                    );
                                }}
                            >
                                {allCadreTypes.map((type: WorkPoolType) => (
                                    <MenuItem value={type.ctKey}>{type.name}</MenuItem>
                                ))}
                            </Select>
                        </>
                    )}
                    {cadreTypesLoading && (
                        <Alert severity="info" sx={{ fontSize: 14 }}>
                            Loading work pool types
                        </Alert>
                    )}
                </Box>
            </Stack>
            <CclDrawerActionBar>
                <CclLoadingButton
                    onClick={() => addWorkPool()}
                    loading={isLoading}
                    disabled={
                        name.length > 0 && description.length > 0 && ctKey !== 0 ? false : true
                    }
                    mode={"primary"}
                >
                    Save
                </CclLoadingButton>
                <CclButton
                    onClick={() => props.onClose(false)}
                    disabled={isLoading}
                    mode={"secondary"}
                >
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddWorkPoolDrawer;
