import * as React from "react";
import { AlertColor, Box, Stack, TextField } from "@mui/material";
import { useAddCclApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../../../utilities/generalUtilities";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

export interface AddApplicationDrawerProps {
    open: boolean;
    onClose: () => void;
    systemId: string;
}

export const AddApplicationDrawer: React.FC<AddApplicationDrawerProps> = (props) => {
    const [newAppName, setNewAppName] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [addApplication, { isLoading }] = useAddCclApplicationMutation();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const onAddApplication = () => {
        addApplication({
            name: newAppName,
            applicationId: generateGUID(),
            systemId: props.systemId,
        })
            .unwrap()
            .then((res) => {
                if (!res) {
                    ShowSnackbarElement("Add Application Failed", "error");
                } else {
                    ShowSnackbarElement("Application Added", "success");
                }
                props.onClose();
            })
            .catch((err) => {
                ShowSnackbarElement("Add Application Failed", "error");
                props.onClose();
            });
    };

    return (
        <>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclDrawer open={props.open} onClose={props.onClose} title="Add Application">
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        <TextField
                            required
                            id="new-application-name"
                            label="New Application Name"
                            variant="outlined"
                            value={newAppName}
                            onChange={(e) => setNewAppName(e.target.value)}
                        />
                        <CclDrawerActionBar>
                            <CclLoadingButton
                                onClick={() => onAddApplication()}
                                disabled={newAppName === ""}
                                mode={"primary"}
                                loading={isLoading}
                            >
                                Save
                            </CclLoadingButton>
                            <CclButton onClick={props.onClose} mode={"secondary"}>
                                Cancel
                            </CclButton>
                        </CclDrawerActionBar>
                    </Stack>
                </Box>
            </CclDrawer>
        </>
    );
};

export default AddApplicationDrawer;
