import * as React from "react";
import { Alert, AlertColor, Box } from "@mui/material";
import { Asset } from "../../../../services/types/enterpriseParticipantApiTypes";
import SessionAssetsDataGrid from "./sessionFileDataGrid/sessionFilesDataGrid";
import { SessionDetailAssignedCoach } from "../../../../services/types/sessionDetailTypes";
import { useDeleteAssetsMutation } from "../../../../services/cclTokenedAssetsApi";
import { useGetCclEventByEventKeyQuery } from "../../../../services/cclTokenedSessionApi";
import { PersonInfo } from "../../../../models/personInfo";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import CclDownloadWarningAlert from "../../../../components/common/cclDownloadWarningAlert";
import SessionFileUploadDrawer from "./fileUploadDialog/sessionFileUploadDrawer";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface SessionFilesSubPanelProps {
    sessionId: string;
    assets: Asset[];
    coaches: SessionDetailAssignedCoach[];
    readOnly?: boolean;
}

const SessionFilesSubPanel: React.FC<SessionFilesSubPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [eventInProgress, setEventInProgress] = React.useState<string>("");
    const [openUploadDrawer, setOpenUploadDrawer] = React.useState<boolean>(false);
    const [personList, setPersonList] = React.useState<PersonInfo[]>([]);
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const [deleteAssets] = useDeleteAssetsMutation();
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    React.useEffect(() => {
        if (props.coaches != null) {
            const people: PersonInfo[] = props.coaches
                .filter(
                    (value, index, self) => index === self.findIndex((t) => t.imKey === value.imKey)
                )
                .map((p) => {
                    return { firstName: p.firstName, lastName: p.lastName, imKey: p.imKey };
                });
            setPersonList(people);
        }
    }, [props.coaches]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        const newids = selectedIds.map((i) => +i);
        setEventInProgress(eventName);
        if (eventName === "download" && selectedIds.length > 30) {
            setSelectedCount(selectedIds.length);
            setShowLargeUploadWarning(true);
            return;
        }
        completeAction(eventName, newids);
    };

    const completeAction = (eventname: string, aikeys: number[]) => {
        switch (eventname) {
            case "upload":
                setOpenUploadDrawer(true);
                break;

            case "download":
                let filename: string = "";
                if (aikeys.length > 1) {
                    filename =
                        sessionDetails != null
                            ? `${sessionDetails.sessionCode}Files.zip`
                            : "SessionFiles.zip";
                } else if (aikeys.length === 1) {
                    let ast = props.assets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        var fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    ShowSnackbarElement("Download Failed", "error");
                    return; // nothing selected
                }

                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: false })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("IndividualFilesDownloaded", evtData);
                        ShowSnackbarElement("Download Complete", "success");
                        setEventInProgress("");
                    })
                    .catch((error) => {
                        ShowSnackbarElement("Download Failed", "error");
                        setEventInProgress("");
                    });
                break;

            case "delete":
                deleteAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("IndividualFilesDeleted", evtData);
                        ShowSnackbarElement("File(s) Deleted", "success");
                        setEventInProgress("");
                    })
                    .catch((error) => {
                        ShowSnackbarElement("File Deletion Failed", "error");
                        setEventInProgress("");
                    });
                break;
        }
    };

    return (
        <Box
            width={1}
            gap={2}
            sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
        >
            <CclDownloadWarningAlert />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <SessionFileUploadDrawer
                sessionId={props.sessionId}
                people={personList}
                open={openUploadDrawer}
                personType={"resource"}
                onClose={() => setOpenUploadDrawer(false)}
                projectCode={sessionDetails?.sessionCode ?? ""}
                isSessionFile={true}
            />
            {personList.length === 0 && (
                <Alert severity="info">
                    No resources assigned. Please assign resources in order to upload files.
                </Alert>
            )}
            <div style={{ flexGrow: 1 }}>
                <SessionAssetsDataGrid
                    coaches={personList}
                    assets={props.assets}
                    handleEvent={onGridToolbarButtonClick}
                    readOnly={props.readOnly}
                    eventInProgress={eventInProgress}
                />
            </div>
        </Box>
    );
};

export default SessionFilesSubPanel;
