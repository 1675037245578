import jwtDecode from "jwt-decode";
import { RouteAccess } from "../../app/slices/allowedRoutesSlice";

export enum eGroupIds {
    eCCLAdmin = "881BD30F-6AE9-42A5-9DE3-0798916D715E",
    eCCLResources = "5C58D6BC-5B5A-4E2B-8A5F-361F8076A401",
    eCCLSuperAdmin = "493C80EC-1646-444F-B42B-57B3A9A98D19",
    eClientAdmin = "97B62DCE-785A-4E92-A35A-532F05F8597E",
    eClientFacilitators = "F766128D-9894-4661-A2A6-25C748590503",
    eNWAAdmin = "0630FDC3-FB47-436F-8529-9560102C8CE9",
    eNWAResource = "2B8F038B-4B56-4D06-B778-39AD9682342D",
}

const eGroupNames: { [key in eGroupIds]: string } = {
    [eGroupIds.eCCLAdmin]: "CCL Admin",
    [eGroupIds.eCCLResources]: "CCL Resource",
    [eGroupIds.eCCLSuperAdmin]: "CCL Super Admin",
    [eGroupIds.eClientAdmin]: "Client Admin",
    [eGroupIds.eClientFacilitators]: "Client Facilitator",
    [eGroupIds.eNWAAdmin]: "NWA Admin",
    [eGroupIds.eNWAResource]: "NWA Resource",
};

export class UserClaimsService {
    private homeGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];
    private sessionGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientAdmin,
    ];
    private registrationGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
    ];
    private registrationDetailsGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientAdmin,
    ];
    private resourceGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
    ];
    private notificationGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];

    private notificationDetailsGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientAdmin,
    ];

    private applicationsGroups: string[] = [eGroupIds.eCCLSuperAdmin];
    private usersGroups: string[] = [eGroupIds.eCCLSuperAdmin, eGroupIds.eCCLAdmin];

    private userDetailsGroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientAdmin,
    ];

    private assessmentsGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];
    private assignmentsGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];
    private logsGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];

    private clientsGroups: string[] = [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin];

    private myAccessgroups: string[] = [
        eGroupIds.eCCLAdmin,
        eGroupIds.eCCLSuperAdmin,
        eGroupIds.eCCLResources,
        eGroupIds.eNWAResource,
        eGroupIds.eNWAAdmin,
        eGroupIds.eClientFacilitators,
    ];

    private limitedMyAccessGroups: string[] = [
        eGroupIds.eNWAAdmin,
        eGroupIds.eNWAResource,
        eGroupIds.eClientFacilitators,
    ];

    private adminGroups: string[] = [eGroupIds.eCCLSuperAdmin];
    private nwaAdminGroup: string[] = [eGroupIds.eNWAAdmin];

    private readOnlyGroups: string[] = [];

    private hideLinksGroups: string[] = [
        eGroupIds.eCCLResources,
        eGroupIds.eNWAResource,
        eGroupIds.eClientFacilitators,
    ];

    private showReadOnlyPopups: string[] = [eGroupIds.eNWAResource, eGroupIds.eCCLResources];

    private isNullOrEmptyString(str: string): boolean {
        return str === "undefined" || str === null || str === "";
    }

    private getDecodedTokenClaim(key: string): any {
        const token = localStorage.getItem("token") as string;
        if (this.isNullOrEmptyString(token)) return null;

        const decoded: any = jwtDecode(token);
        if (decoded == null || this.isNullOrEmptyString(decoded[key])) return "";

        return decoded[key];
    }

    public ClearToken(): any {
        localStorage.removeItem("token");
    }

    public StoreToken(rawtoken: string): any {
        localStorage.setItem("token", rawtoken);
        if (this.isNullOrEmptyString(rawtoken)) return null;

        return jwtDecode(rawtoken);
    }

    public AreClaimsStored(): boolean {
        const token = localStorage.getItem("token") as string;
        return !this.isNullOrEmptyString(token);
    }

    public GetCurrentUserEmail(): string {
        return this.getDecodedTokenClaim("email");
    }

    public GetCurrentUserName(): string {
        return this.getDecodedTokenClaim("name");
    }

    public GetCurrentUserAppRoles(): number[] {
        let value: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/access/user/app/roles"
        );

        if (value == null) {
            return [];
        }

        return value.split(",").map((v: string) => {
            return +v;
        });
    }

    public IsUserInAppRole(role: number): boolean {
        let roles = this.GetCurrentUserAppRoles();
        for (let i = 0; i < roles.length; i++) {
            if (roles[i] === role) {
                return true;
            }
        }
        return false;
    }

    public GetCurrentUserId(): string {
        let value = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/ccl-identity/id"
        );
        return value;
    }

    public GetCurrentResourceId(): string {
        return this.getDecodedTokenClaim("http://schemas.ccl.org/accounts/claims/ccl-resource/id");
    }

    public GetCurrentUserPicture(): string {
        return this.getDecodedTokenClaim("picture");
    }

    public ShouldSeeAccessInternalLinks(): boolean {
        const userGroupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const userGroupIds: string[] = userGroupidsStr.split(",");
        return !this.isInGroup(userGroupIds, this.hideLinksGroups);
    }
    public IsUserInGroups(groupIds: eGroupIds[]): boolean {
        const userGroupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const userGroupIds: string[] = userGroupidsStr.split(",");
        return this.isInGroup(userGroupIds, groupIds);
    }

    public CanUserViewMyAccess(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");

        if (this.isInGroup(groupIds, this.limitedMyAccessGroups)) return true;

        const pkey = this.GetCurrentResourceId();
        if (pkey === undefined || pkey === null || pkey === "") return false;

        return this.isInGroup(groupIds, this.myAccessgroups);
    }

    public IsUserLimitedMyAccess(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");

        return this.isInGroup(groupIds, this.limitedMyAccessGroups);
    }

    public IsUserCclAdminOrSuperAdmin(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");
        return this.isInGroup(groupIds, [eGroupIds.eCCLAdmin, eGroupIds.eCCLSuperAdmin]);
    }

    public IsUserSuperAdmin(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        if (groupidsStr === null) return false;
        const groupIds: string[] = groupidsStr.split(",");
        return this.isInGroup(groupIds, this.adminGroups);
    }

    public IsReadOnly(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");
        return this.isInGroup(groupIds, this.readOnlyGroups) && !this.IsUserSuperAdmin();
    }

    public IsUserNwaAdmin(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        if (!groupidsStr) {
            return false;
        }
        const groupIds: string[] = groupidsStr.split(",");
        return this.isInGroup(groupIds, this.nwaAdminGroup);
    }

    public IsUserReadonlyPopupUser(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");
        return this.isInGroup(groupIds, this.showReadOnlyPopups);
    }

    public NwaAdminGroup(): string | null {
        const adminPartnersStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/user/partner/groups"
        );
        if (!adminPartnersStr) {
            return null;
        }
        const adminPartners: string[] = adminPartnersStr.split(",");
        return adminPartners[0]; // NOTE: for now we just return the first id, we don't anticipate nwa admins in multiple partner groups
    }

    public NwaAdminGroupId(): string | null {
        const adminPartnerIdsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/user/partner/ids"
        );
        if (!adminPartnerIdsStr) {
            return null;
        }
        const adminPartnerIds: string[] = adminPartnerIdsStr.split(",");
        return adminPartnerIds[0]; // NOTE: for now we just return the first id, we don't anticipate nwa admins in multiple partner groups
    }

    public GetCurrentUserGroup(): string {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        if (!groupidsStr) {
            return "Error";
        }
        const groupIds: string[] = groupidsStr.split(",");
        if (!groupIds[0]) {
            return "Error";
        }
        const group: eGroupIds = groupIds[0].toUpperCase() as eGroupIds;
        return eGroupNames[group];
    }

    public GetCurrentAllowedRoutes(): RouteAccess {
        if (!this.tokenExists()) {
            const r: RouteAccess = {
                allowHome: true,
                allowSessions: false,
                allowRegistrations: false,
                allowRegistrationDetails: false,
                allowResources: false,
                allowNotifications: false,
                allowNotificationDetails: false,
                allowApplications: false,
                allowUsers: false,
                allowUserDetails: false,
                allowAssessments: false,
                allowAssignments: false,
                allowAdmin: false,
                allowLogs: false,
                allowMyAccess: false,
                allowClients: false,
            };
            return r;
        }
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        const groupIds: string[] = groupidsStr.split(",");
        let ra: RouteAccess = {
            allowHome: this.isInGroup(groupIds, this.homeGroups),
            allowSessions: this.isInGroup(groupIds, this.sessionGroups),
            allowRegistrations: this.isInGroup(groupIds, this.registrationGroups),
            allowRegistrationDetails: this.isInGroup(groupIds, this.registrationDetailsGroups),
            allowResources: this.isInGroup(groupIds, this.resourceGroups),
            allowNotifications: this.isInGroup(groupIds, this.notificationGroups),
            allowNotificationDetails: this.isInGroup(groupIds, this.notificationDetailsGroups),
            allowApplications: this.isInGroup(groupIds, this.applicationsGroups),
            allowUsers: this.isInGroup(groupIds, this.usersGroups),
            allowUserDetails: this.isInGroup(groupIds, this.userDetailsGroups),
            allowAssessments: this.isInGroup(groupIds, this.assessmentsGroups),
            allowAssignments: this.isInGroup(groupIds, this.assignmentsGroups),
            allowAdmin: this.isInGroup(groupIds, this.adminGroups),
            allowLogs: this.isInGroup(groupIds, this.logsGroups),
            allowMyAccess: this.isInGroup(groupIds, this.myAccessgroups),
            allowClients: this.isInGroup(groupIds, this.clientsGroups),
        };

        return ra;
    }

    private isInGroup(userGroups: string[], roleList: string[]): boolean {
        const roleListUpper: string[] = roleList.map((r) => r.toUpperCase());
        return userGroups.some((gid) => roleListUpper.includes(gid.toUpperCase()));
    }

    private tokenExists(): boolean {
        var token = localStorage.getItem("token") as string;
        return !this.isNullOrEmptyString(token);
    }
}
