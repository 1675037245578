import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { EnterpriseUser } from "../../services/types/search.service.types";
import { useSelector } from "react-redux";
import CclPersistentDataGrid from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import { updateUserSearchGridState } from "../../app/slices/userSearchListTableStateSlice";
import CclCommonLink from "../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

const COLUMNS = [
    {
        field: "imKey",
        headerName: "ID",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
            />
        ),
        width: 150,
    },
    {
        field: "name",
        headerName: "User",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/user?email=${encodeURI(params.row.email)}&imkey=${params.row.imKey}`}
            />
        ),
        flex: 0.8,
        valueGetter: getFullName,
    },
    {
        field: "email",
        headerName: "Email",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`mailto:${params.value}`}
                type={"email"}
            />
        ),
        flex: 1.25,
    },
];

export interface UserSearchListProps {
    users: EnterpriseUser[];
    loading: boolean;
    hideToolbar?: boolean;
    createNewUser: (rows?: any[]) => any;
}

export const UserSearchDataGridPro: React.FC<UserSearchListProps> = (props) => {
    const claimsService = new UserClaimsService();
    const stateSelector = useSelector((state: any) => state.userSearchListTableState);
    const isUserAdmin = claimsService.IsUserSuperAdmin();

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.users}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={6}
            getRowIdFn={(row: EnterpriseUser) => row.imKey}
            actionCreator={updateUserSearchGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "email", sort: "asc" }] },
            })}
            resultBar={false}
            resultBarText={""}
            miscButtons={
                isUserAdmin
                    ? [
                          {
                              id: "create-new-user-button",
                              caption: "Create User",
                              onClick: props.createNewUser,
                              returnSelected: true,
                              restricted: true,
                          },
                      ]
                    : []
            }
            showCheckboxes={false}
            hideFooter={false}
        />
    );
};

export default UserSearchDataGridPro;
