import React from "react";
import { Box, TextField } from "@mui/material";
import CclButton from "../cclButtons/cclButton";

interface CclDoubleTextSearchBarProps {
    initialFieldOneSearchTerm: string;
    fieldOneSearchLabel: string;
    initialFieldTwoSearchTerm: string;
    fieldTwoSearchLabel: string;
    executeSearch: (fieldOneSearchTerm: string, fieldTwoSearchTerm: string) => void;
    errorStateChange?: (error: boolean) => void;
    requireBothFields: boolean;
    suppressErrors: boolean;
}

const CclDoubleTextSearchBar: React.FC<CclDoubleTextSearchBarProps> = (props) => {
    const [fieldOneSearchString, setFieldOneSearchString] = React.useState<string>(
        props.initialFieldOneSearchTerm ?? ""
    );
    const [fieldTwoSearchString, setFieldTwoSearchString] = React.useState<string>(
        props.initialFieldTwoSearchTerm ?? ""
    );

    React.useEffect(() => {
        setFieldOneSearchString(props.initialFieldOneSearchTerm ?? "");
    }, [props.initialFieldOneSearchTerm]);

    React.useEffect(() => {
        setFieldTwoSearchString(props.initialFieldTwoSearchTerm ?? "");
    }, [props.initialFieldTwoSearchTerm]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
        flexGrow: 1,
    };

    const handleKeywordSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (fieldOneSearchString !== "" || fieldTwoSearchString !== "") {
                evt.preventDefault();
                props.executeSearch(fieldOneSearchString, fieldTwoSearchString);
            }
        }
    };

    return (
        <Box
            gap={2}
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <TextField
                value={fieldOneSearchString === "*" ? "" : fieldOneSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field-one"
                label={props.fieldOneSearchLabel ?? "Field One Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setFieldOneSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
            />

            <TextField
                value={fieldTwoSearchString === "*" ? "" : fieldTwoSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field-two"
                label={props.fieldTwoSearchLabel ?? "Field Two Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setFieldTwoSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e)}
            />

            <CclButton
                onClick={() =>
                    props.executeSearch(
                        fieldOneSearchString?.trim() ?? "",
                        fieldTwoSearchString?.trim() ?? ""
                    )
                }
                aria-label="Search By ID or Keyword"
                sx={{ flexShrink: 0, whiteSpace: "nowrap" }}
                disabled={fieldOneSearchString === "" && fieldTwoSearchString === ""}
                mode={"primary"}
            >
                Search
            </CclButton>
        </Box>
    );
};

export default CclDoubleTextSearchBar;
