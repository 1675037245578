import * as React from "react";
import { AlertColor, Box, TextField } from "@mui/material";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import { AccessEventIndividualDetails } from "../../services/types/accessEventTypes";
import { CreateEnterpriseUserRequest } from "../../services/types/enterpriseUserApiTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { useCreateNewEnterpriseUserMutation } from "../../services/cclTokenedEnterpriseUserApi";
import { useNavigate } from "react-router-dom";
import CclLoadingButton from "../../components/common/cclButtons/cclLoadingButton";
import { validateEmail } from "../../utilities/generalUtilities";
import CclDrawerActionBar from "../../components/common/cclDrawer/cclDrawerActionBar";

export type CreateNewUserDrawerProps = {
    open: boolean;
    onClose: () => void;
    showSnackbar: (message: string, severity: AlertColor) => any;
};

const CreateNewUserDrawer: React.FC<CreateNewUserDrawerProps> = (props) => {
    const title = "Create User";
    const [email, setEmail] = React.useState<string>("");
    const [emailInputError, setEmailInputError] = React.useState<string>("");
    const [first, setFirst] = React.useState<string>("");
    const [firstInputError, setFirstInputError] = React.useState<string>("");
    const [last, setLast] = React.useState<string>("");
    const [lastInputError, setLastInputError] = React.useState<string>("");

    const [triggerCreateNewUser, { isLoading: createUserLoading }] =
        useCreateNewEnterpriseUserMutation();

    const { logEvent } = useLogAccessEvent();
    let navigate = useNavigate();

    React.useEffect(() => {
        if (props.open) return;
        setFirst("");
        setLast("");
        setEmail("");
    }, [props.open]);

    const createNewUser = async (email: string, first: string, last: string) => {
        const payload: CreateEnterpriseUserRequest = {
            firstName: first,
            lastName: last,
            email: email,
        };
        triggerCreateNewUser(payload)
            .unwrap()
            .then((response) => {
                if (response) {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: response.imKey.toString(),
                        email: response.email,
                    };

                    logEvent("NewUserCreated", evtData);
                    navigate(
                        `/user?email=${encodeURI(response.email)}&imkey=${
                            response.imKey
                        }&firstName=${response.firstName}&lastName=${response.lastName}`
                    );
                }
            })
            .catch(() => {
                props.showSnackbar("User Creation Failed", "error");
                props.onClose();
            });
    };

    const handleOk = () => {
        let eie: string = "";
        let fie: string = "";
        let lie: string = "";

        if (!validateEmail(email)) eie = "Not a valid email address";
        if (first === "") fie = "First name can not be empty";
        if (last === "") lie = "Last name can not be empty";

        setEmailInputError(eie);
        setFirstInputError(fie);
        setLastInputError(lie);

        if (eie !== "" || fie !== "" || lie !== "") return;

        createNewUser(email, first, last);
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (
        <CclDrawer title={title} open={props.open} onClose={props.onClose}>
            <Box>
                <TextField
                    name="newUserFirstName"
                    placeholder="New User's First Name"
                    value={first}
                    id="outlined-adornment-new-user-first-name"
                    label="New User's First Name"
                    variant="outlined"
                    fullWidth={true}
                    error={firstInputError !== ""}
                    helperText={firstInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setFirst(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    name="newUserLastName"
                    placeholder="New User's Last Name"
                    value={last}
                    id="outlined-adornment-new-user-last-name"
                    label="New User's Last Name"
                    variant="outlined"
                    fullWidth={true}
                    error={lastInputError !== ""}
                    helperText={lastInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setLast(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
                <TextField
                    name="newUserEmail"
                    placeholder="New User's Email"
                    value={email}
                    id="outlined-adornment-new-user-email"
                    label="New User's Email"
                    variant="outlined"
                    fullWidth={true}
                    error={emailInputError !== ""}
                    helperText={emailInputError}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 2 }}
                />
            </Box>
            <CclDrawerActionBar>
                <CclLoadingButton
                    id="ok-btn"
                    onClick={handleOk}
                    mode={"primary"}
                    loading={createUserLoading}
                >
                    Save
                </CclLoadingButton>
                <CclButton id="cancel-btn" mode="secondary" onClick={props.onClose}>
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default CreateNewUserDrawer;
