import React, { useEffect } from "react";
import { Autocomplete, TextField, Box, AlertColor } from "@mui/material";
import {
    AssessmentList,
    AssessmentListItem,
} from "../../../../../services/types/sessionDetailTypes";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../../components/common/cclButtons/cclButton";

interface AddAssessmentDrawerProps {
    open: boolean;
    esKey: number;
    onCancel: () => void;
    onAdd: (newItem: AssessmentListItem) => void;
    assessmentOptionsList: AssessmentList[];
    showSnackbar: (message: string, severity: AlertColor) => void;
}

const AddAssessmentDrawer: React.FC<AddAssessmentDrawerProps> = (props) => {
    const [assessmentValue, setAssessmentValue] = React.useState<string | undefined | null>(
        props.assessmentOptionsList?.length > 0
            ? props.assessmentOptionsList[0].assessmentName
            : null
    );

    useEffect(() => {
        if (
            assessmentValue == null &&
            props.assessmentOptionsList != null &&
            props.assessmentOptionsList.length > 0
        ) {
            setAssessmentValue(props.assessmentOptionsList[0].assessmentName);
        }
    }, [props.assessmentOptionsList, assessmentValue]);

    const textInputSx = {
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const getDefaultAssessmentValue = (): string | null => {
        return props.assessmentOptionsList != null && props.assessmentOptionsList.length > 0
            ? props.assessmentOptionsList[0].assessmentName
            : null;
    };

    const close = () => {
        setAssessmentValue(getDefaultAssessmentValue());
        props.onCancel();
    };

    const onAssessmentChange = (e: any, newValue: any) => {
        if (newValue == null) {
            setAssessmentValue(props.assessmentOptionsList[0].assessmentName);
            return;
        }
        setAssessmentValue(newValue);
    };

    const addAssessment = () => {
        if (assessmentValue) {
            const aId =
                props.assessmentOptionsList.filter((e) => e.assessmentName === assessmentValue)[0]
                    ?.assessmentID ?? "0";
            if (aId !== "0") {
                let aIdPieces = aId.split(" ");
                let item = {
                    esKey: props.esKey,
                    bPI_Key: 0,
                    bPI_Seq_Num: 1,
                    bPIT_Key: 1,
                    cclMasterKey: +aIdPieces[0],
                    assessmentType: "Assessment",
                    instrumentTypeKey: +aIdPieces[1],
                    cclMasterRaterKey: +aIdPieces[2],
                    title: assessmentValue!,
                    status: "Add",
                    groupReportRequested: false,
                    aggregateReportRequested: false,
                    aggregateReportAvailable: false,
                    groupReportAvailable: false,
                    docA_Path: "",
                    assetKey: 0,
                };
                props.onAdd(item);
            } else {
                props.showSnackbar("Assessments & Documents Update Failed", "error");
            }
        } else {
            props.showSnackbar("Assessments & Documents Update Failed", "error");
        }
    };

    return (
        <CclDrawer title={"Add Assessment(s)"} open={props.open} onClose={close}>
            <Box sx={{ paddingTop: 1 }}>
                <Autocomplete
                    disabled={props.assessmentOptionsList == null}
                    disablePortal={false}
                    id="combo-assessment"
                    value={assessmentValue || null}
                    onChange={(e: any, newValue: string | null) => onAssessmentChange(e, newValue)}
                    options={props.assessmentOptionsList.map((option) => option.assessmentName)}
                    sx={{ width: 1 }}
                    renderInput={(params) => (
                        <TextField {...params} sx={textInputSx} label="Assessment List" />
                    )}
                    disableClearable={
                        props.assessmentOptionsList[0] == null ||
                        assessmentValue === props.assessmentOptionsList[0].assessmentName
                    }
                />
            </Box>
            <CclDrawerActionBar>
                <CclButton
                    onClick={addAssessment}
                    disabled={assessmentValue == null}
                    mode={"primary"}
                >
                    Add
                </CclButton>
                <CclButton onClick={close} mode={"secondary"}>
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddAssessmentDrawer;
