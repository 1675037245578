import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import { PageLoader } from "./components/common/pageLoader";
import { Navigate, Route, Routes } from "react-router-dom";
import { Error } from "./pages/error";
//import { install, gtag } from 'ga-gtag';
import { Sessions } from "./pages/sessions/sessions";
import { ThemeProvider } from "@mui/material/styles";
import useTheme from "./hooks/useTheme";
import createTheme from "./theme";
import { LicenseInfo } from "@mui/x-license-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import Resources from "./pages/resources/resources";
import Registrations from "./pages/registrations/registrations";
import { ProtectedRoute } from "./components/common/protected-route";
import SessionDetail from "./pages/sessions/sessionDetail/sessionDetail";
import ResourceDetail from "./pages/resources/resourceDetail/resourceDetail";
import Assignments from "./pages/assignments/assignments";
import Users from "./pages/users/users";
import Home from "./pages/home/home";
import MyAccess from "./pages/my-access/myaccess";
import Notifications from "./pages/notifications/notifications";
import NotificationDetail from "./pages/notifications/notificationDetail/notificationDetail";
import RegistrationDetail from "./pages/registrations/registrationDetail/registrationDetail";
import TemplatePage from "./pages/emailTemplates/emailTemplatePage";
import PartnerDetail from "./pages/resources/partnerDetail/partnerDetail";
import { updateSessionsGridState } from "./app/slices/sessionListTableStateSlice";
import { useLazyGetUserPreferencesByEmailQuery } from "./services/cclTokenedUserPreferenceApi";
import { UserClaimsService, eGroupIds } from "./services/currentUserService/currentUserService";
import { UserPreference } from "./services/types/userPreferenceTypes";
import { useDispatch, useSelector } from "react-redux";
import { addUserClaims } from "./app/slices/userClaimsSlice";
import { updateResourceGridState } from "./app/slices/resourceListTableStateSlice";
import { updateRegistrationGridState } from "./app/slices/registrationListTableStateSlice";
import { updateNotificationsGridState } from "./app/slices/notificationListTableStateSlice";
import Applications from "./pages/applications/applications";
import { updateUserSearchGridState } from "./app/slices/userSearchListTableStateSlice";
import UserDetail from "./pages/users/userDetail/userDetail";
import { AllowedRoutesState, updateAllowedRoutes } from "./app/slices/allowedRoutesSlice";
import { updateProductSaleGridState } from "./app/slices/productSaleListTableStateSlice";
import { updateAssignmentGridState } from "./app/slices/assignmentListTableStateSlice";
import AccessLogs from "./pages/accesslogs/accessLogs";
import ApplicationDetail from "./pages/applications/applicationDetail/applicationDetail";
import { updateAccessEventListGridState } from "./app/slices/accessEventListTableStateSlice";
import SessionTimeOut from "./components/common/sessionTimeOut";
import { updatePartnerResourceGridState } from "./app/slices/partnerResourceListTableStateSlice";
import { updateApplicationUsersSearchGridState } from "./app/slices/applicationUsersSearchListTableStateSlice";
import { updateApplicationRolesSearchGridState } from "./app/slices/applicationRolesSearchListTableStateSlice";
import WorkPoolDetail from "./pages/resources/workPools/workPoolDetails/workPoolDetail";
import { updateWorkPoolResourceGridState } from "./app/slices/workPoolsResourceListTableStateSlice";
import { updateApplicationsSearchGridState } from "./app/slices/applicationSearchListTableStateSlice";
import { registerLicense } from "@syncfusion/ej2-base";
import { FeatureFlagProvider } from "./featureFlags";
import SystemDetail from "./pages/applications/systemDetail/systemDetail";
import { updateBiosGridState } from "./app/slices/resourceBioSearchGridSlice";
import { updateAttributesGridState } from "./app/slices/resourceAttributesSearchListSlice";
import { updateContractsGridState } from "./app/slices/resourceContractsSearchListSlice";
import RedirectToEmail from "./pages/notifications/notificationDetail/RedirectToEmail";
import EmailTemplateDetail from "./pages/emailTemplates/EmailTemplateDetails/emailTemplateDetailsPage";
import Assessments from "./pages/assessments/assessments";
import Clients from "./pages/clients/clients";
import { updateScoreReportDownloadGridState } from "./app/slices/scoreReportDownloadListTableStateSlice";
import { updateApplicationInvitationsSearchGridState } from "./app/slices/applicationInvitationsSearchListTableStateSlice";

export const App: React.FC = () => {
    const allowedRoutes: AllowedRoutesState = useSelector((state: any) => state.allowedRoutes);

    //MUIX License
    LicenseInfo.setLicenseKey(
        "a302aae095aea416722b3c2f00072d8bTz0xMDM3NjgsRT0xNzY1MTA5NjI4MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
    );

    // Registering Syncfusion license key
    registerLicense(
        "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdkxiWn1ecHJVQGNY"
    );

    // eslint-disable-next-line
    const claimsService = React.useMemo(() => new UserClaimsService(), []);
    const { error, isLoading, isAuthenticated, getIdTokenClaims } = useAuth0();

    const [defaultPage, setDefaultPage] = React.useState<string>("");
    const [unlock, setUnlock] = React.useState<boolean>(false);

    const [getUserPreferences] = useLazyGetUserPreferencesByEmailQuery();
    const { theme } = useTheme();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isAuthenticated) return;

        getIdTokenClaims().then((claims) => {
            const claimsObj = claimsService.StoreToken(claims?.__raw as string);
            if (claimsObj != null) {
                dispatch(addUserClaims(claimsObj));

                const routes = claimsService.GetCurrentAllowedRoutes();
                if (routes != null) {
                    dispatch(updateAllowedRoutes(routes));
                }

                getUserPreferences(claimsObj.email)
                    .unwrap()
                    .then((userPrefs) => {
                        let up: UserPreference | undefined = userPrefs.find(
                            (p) => p.userPreferenceTypeId === 1
                        );
                        // save session table preference to store
                        dispatch(updateSessionsGridState(up?.data ?? null));

                        // save resource table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 2);
                        dispatch(updateRegistrationGridState(up?.data ?? null));

                        // save notification table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 3);
                        dispatch(updateNotificationsGridState(up?.data ?? null));

                        // save resource table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 4);
                        dispatch(updateResourceGridState(up?.data ?? null));

                        // save enterprise user table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 6);
                        dispatch(updateUserSearchGridState(up?.data ?? null));

                        // save applications table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 7);
                        dispatch(updateApplicationsSearchGridState(up?.data ?? null));

                        // save assignments table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 8);
                        dispatch(updateAssignmentGridState(up?.data ?? null));

                        // save product sales table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 9);
                        dispatch(updateProductSaleGridState(up?.data ?? null));

                        // save log table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 10);
                        dispatch(updateAccessEventListGridState(up?.data ?? null));

                        // save partner resource table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 11);
                        dispatch(updatePartnerResourceGridState(up?.data ?? null));

                        // save application user table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 12);
                        dispatch(updateApplicationUsersSearchGridState(up?.data ?? null));

                        // save resource bio list grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 13);
                        dispatch(updateBiosGridState(up?.data ?? null));

                        // save resource attribute grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 14);
                        dispatch(updateAttributesGridState(up?.data ?? null));

                        // save resource contracts grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 15);
                        dispatch(updateContractsGridState(up?.data ?? null));

                        // save resource workpools grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 18);
                        dispatch(updateWorkPoolResourceGridState(up?.data ?? null));

                        // save score report download grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 19);
                        dispatch(updateScoreReportDownloadGridState(up?.data ?? null));

                        // save application invitation table preference to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 20);
                        dispatch(updateApplicationInvitationsSearchGridState(up?.data ?? null));

                        up = userPrefs.find((p) => p.userPreferenceTypeId === 16);
                        if (up != null) {
                            let data = JSON.parse(up?.data);
                            setDefaultPage(data.url ? data.url : "");
                        } else {
                            setDefaultPage("");
                        }
                        setUnlock(true);

                        // save application roles list grid to store
                        up = userPrefs.find((p) => p.userPreferenceTypeId === 17);
                        dispatch(updateApplicationRolesSearchGridState(up?.data ?? null));
                    });
            }
        });
        // eslint-disable-next-line
    }, [isAuthenticated]);

    // richie: this some google analytics code to review and upgrade from useeffects()
    // install('G-32KMZ61TPS'); // install google analytics in head, only runs once per session
    // useEffect(() => {
    //   gtag('send', 'pageview');
    // }, [location]);

    // ---- sample google analytics call ----
    // gtag('event', 'vt-startup', {
    //   title: 'title - vt',
    // })
    const getRootPathElement = (): JSX.Element => {
        if (allowedRoutes.routeFlags.allowHome) {
            if (unlock) {
                if (defaultPage) {
                    return <Navigate to={defaultPage} />;
                } else {
                    return <Navigate to="/home" replace />;
                }
            } else {
                return <ProtectedRoute component={Home} />;
            }
        }

        if (
            allowedRoutes.routeFlags.allowSessions &&
            claimsService.IsUserInGroups([eGroupIds.eClientAdmin, eGroupIds.eNWAAdmin])
        ) {
            return <Navigate to="/sessions" replace />;
        }

        if (allowedRoutes.routeFlags.allowMyAccess) {
            return <Navigate to="/myaccess" replace />;
        }

        return <Navigate to="/resources" replace />;
    };

    if (isLoading && !isAuthenticated) {
        return (
            <div>
                <PageLoader msg={""} />
            </div>
        );
    }

    if (error !== undefined) {
        console.log(error.message);
        return (
            <div>
                <Error />
            </div>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils}>
            <ThemeProvider theme={createTheme(theme)}>
                <SessionTimeOut />
                <FeatureFlagProvider>
                    <Routes>
                        {allowedRoutes.routeFlags.allowAssessments && (
                            <Route
                                path="/assessments"
                                element={<ProtectedRoute component={Assessments} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowAssessments && (
                            <Route
                                path="/assessments/:tabIndex"
                                element={<ProtectedRoute component={Assessments} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowAssignments && (
                            <Route
                                path="/assignments"
                                element={<ProtectedRoute component={Assignments} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowApplications && (
                            <Route
                                path="/applications"
                                element={<ProtectedRoute component={Applications} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowApplications && (
                            <Route
                                path="/system/:systemId"
                                element={<ProtectedRoute component={SystemDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowApplications && (
                            <Route
                                path="/application/:applicationId"
                                element={<ProtectedRoute component={ApplicationDetail} />}
                            />
                        )}
                        <Route path="/error" element={<Error />} />
                        {allowedRoutes.routeFlags.allowNotifications && (
                            <Route path="/notifications" element={<Navigate to="/emails" />} />
                        )}
                        {allowedRoutes.routeFlags.allowNotifications && (
                            <Route
                                path="/emails"
                                element={<ProtectedRoute component={Notifications} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowNotifications && (
                            <Route
                                path="/emails/templates"
                                element={<ProtectedRoute component={TemplatePage} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowNotifications && (
                            <Route
                                path="/emails/templates/:id"
                                element={<ProtectedRoute component={EmailTemplateDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowNotificationDetails && (
                            <Route
                                path="/notification/:notificationId"
                                element={<RedirectToEmail />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowNotificationDetails && (
                            <Route
                                path="/email/:notificationId"
                                element={<ProtectedRoute component={NotificationDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowRegistrations && (
                            <Route
                                path="/registrations"
                                element={<ProtectedRoute component={Registrations} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowRegistrations && (
                            <Route
                                path="/registrations/:searchparam"
                                element={<ProtectedRoute component={Registrations} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowRegistrationDetails && (
                            <Route
                                path="/registration/:esiKey"
                                element={<ProtectedRoute component={RegistrationDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowResources && (
                            <Route
                                path="/resources"
                                element={<ProtectedRoute component={Resources} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowResources && (
                            <Route
                                path="/resources/:target"
                                element={<ProtectedRoute component={Resources} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowResources && (
                            <Route
                                path="/resource/:pKey/:target"
                                element={<ProtectedRoute component={ResourceDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowResources && (
                            <Route
                                path="/partner/:imKey"
                                element={<ProtectedRoute component={PartnerDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowResources && (
                            <Route
                                path="/work-pool/:id"
                                element={<ProtectedRoute component={WorkPoolDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowClients && (
                            <Route path="/clients" element={<ProtectedRoute component={Clients} />} />
                        )}
                        {allowedRoutes.routeFlags.allowSessions && (
                            <Route
                                path="/sessions"
                                element={<ProtectedRoute component={Sessions} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowSessions && (
                            <Route
                                path="/session/:sessionId"
                                element={<ProtectedRoute component={SessionDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowSessions && (
                            <Route
                                path="/session/:sessionId/:tabIndex"
                                element={<ProtectedRoute component={SessionDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowUsers && (
                            <Route path="/users" element={<ProtectedRoute component={Users} />} />
                        )}
                        {allowedRoutes.routeFlags.allowUserDetails && (
                            <Route
                                path="/user"
                                element={<ProtectedRoute component={UserDetail} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowLogs && (
                            <Route
                                path="/events"
                                element={<ProtectedRoute component={AccessLogs} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowMyAccess && (
                            <Route
                                path="/myaccess"
                                element={<ProtectedRoute component={MyAccess} />}
                            />
                        )}
                        {allowedRoutes.routeFlags.allowHome && (
                            <Route path="/home" element={<ProtectedRoute component={Home} />} />
                        )}
                        <Route path="/" element={getRootPathElement()} />
                    </Routes>
                </FeatureFlagProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};
