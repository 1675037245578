import * as React from "react";
import { Box, Stack, TextField } from "@mui/material";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";

export interface UpdateLoginApplicationCallbackDrawerProps {
    open: boolean;
    onClose: () => void;
    url: string;
    loginApplicationCallbackUrlId: string;
    onSave: (url: string, callbackUrlId: string) => void;
}

export const UpdateLoginApplicationCallbackDrawer: React.FC<
    UpdateLoginApplicationCallbackDrawerProps
> = (props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onUpdateCallback = () => {
        if (inputRef.current === null || inputRef.current.value === "") return;

        props.onSave(inputRef.current.value, props.loginApplicationCallbackUrlId);
        props.onClose();
    };

    var verbage = props.loginApplicationCallbackUrlId === "" ? "Add" : "Edit";
    return (
        <CclDrawer open={props.open} onClose={props.onClose} title={`${verbage} Callback URL`}>
            <Box component="form" noValidate autoComplete="off">
                <Stack direction="column" spacing={2}>
                    <TextField
                        required
                        id="edit-callback-url"
                        label="{verbage} Callback URL"
                        variant="outlined"
                        defaultValue={props.url}
                        inputRef={inputRef}
                    />
                    <CclDrawerActionBar>
                        <CclButton onClick={() => onUpdateCallback()} mode={"primary"}>
                            Save
                        </CclButton>
                        <CclButton onClick={props.onClose} mode={"secondary"}>
                            Cancel
                        </CclButton>
                    </CclDrawerActionBar>
                </Stack>
            </Box>
        </CclDrawer>
    );
};

export default UpdateLoginApplicationCallbackDrawer;
